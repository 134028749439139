<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <v-row>
        <v-col xxs="12">
          <v-form ref="sendForm" v-model="valid" lazy-validation>
            <v-card class="mb-4 pa-5">
              <v-text-field
                v-model="getMyProfile.userName"
                label="Your Name"
                outlined
                disabled
                dense
                :rules="[rules.required]"
              ></v-text-field>

              <v-file-input
                label="Your Profile Picture"
                v-model="file"
                outlined
                disabled
                dense
              ></v-file-input>

              <v-text-field
                v-model="getMyProfile.email"
                label="Your Email"
                outlined
                disabled
                dense
                :rules="[rules.required]"
              ></v-text-field>

              <v-text-field
                v-model="getMyProfile.phoneNumber"
                label="Your Phone Number"
                outlined
                disabled
                dense
                :rules="[rules.required]"
              ></v-text-field>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  template: "#page",

  props: ["company"],

  data() {
    return {
      file: null,
      valid: false,
      rules: {
        required: (value) => !!value || "This field is required",
        email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
      },
    };
  },

  computed: {
    ...mapState(["myProfile"]),
    ...mapGetters(["getMyProfile", "messageAlert"]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyCompany");
      }
    },
  },

  methods: {
    validateProfileForm() {
      if (this.$refs.sendForm.validate()) {
        // send it
        this.submitCompany();
      }
    },

    submitCompany() {
      var metadata = {
        contentType: "image/jpeg",
      };
      var goON = true;
      const context = this;

      if (context.file !== null) {
        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef
          .child("webimages/api/accounts/" + context.file.name)
          .put(context.file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          function(error) {
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                console.log("File available at", downloadURL);
                context.getMyProfile.imageURL = downloadURL;

                console.log(context.getMyProfile);
                context.$store.dispatch(
                  "updateMyProfile",
                  context.getMyProfile
                );
              });
          }
        );
      } else {
        context.$store.dispatch("updateMyProfile", context.getMyProfile);
      }
    },
  },
};
</script>
