<template>
  <v-row>
    <v-col cols="12" class="py-0  pa-5">
      <h1 class="font-weight-light mb-0">Company Settings</h1>
      <span class="overline" v-if="getFreelancerCompany.id"
        >Manage Your company here</span
      >
      <span class="overline" v-else
        >No Company is selected register Your company here</span
      >
    </v-col>
    <v-col cols="12" class="py-0">
      <v-tabs v-model="selectedForm" background-color="primary" dark>
        <v-tab v-for="activeForm in companyFormsViews" :key="activeForm.id">
          {{ activeForm.text }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="selectedForm">
        <v-tab-item>
          <v-row>
            <v-col xxs="12">
              <v-form ref="sendForm" v-model="valid" lazy-validation>
                <v-card class="mb-4 pa-5">
                  <v-text-field
                    v-model="getFreelancerCompany.companyName"
                    label="Company Name"
                    autocomplete="company"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>

                  <vuetify-google-autocomplete
                    id="map"
                    :country="['ug']"
                    v-bind:disabled="false"
                    classname="form-control"
                    prepend-inner-icon="place"
                    label="Company Location"
                    v-model="getFreelancerCompany.locationLabel"
                    outlined
                    dense
                    v-on:placechanged="getAddressData"
                    :rules="[rules.required]"
                  >
                  </vuetify-google-autocomplete>

                  <v-text-field
                    v-model="getFreelancerCompany.companyEmail"
                    label="Company Email"
                    autocomplete="email"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-text-field
                    v-model="getFreelancerCompany.companyWebsite"
                    label="Company Website"
                    autocomplete="website"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-text-field
                    v-model="getFreelancerCompany.companyPhone"
                    label="Company Phone"
                    autocomplete="phone"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>

                  <v-textarea
                    v-model="getFreelancerCompany.companyDescription"
                    label="Company Description"
                    rows="4"
                    max-rows="4"
                    outlined
                    dense
                    :rules="[rules.required]"
                  />

                  <v-file-input
                    label="Company Avator"
                    v-model="file"
                    outlined
                    dense
                  ></v-file-input>

                  <v-btn
                    v-if="getFreelancerCompany.id"
                    class="mb-1"
                    @click="validateCompanyUpdateForm"
                    variant="success"
                    >Update</v-btn
                  >

                  <v-btn
                    v-else
                    class="mb-1"
                    @click="validateNewCompanyForm"
                    variant="success"
                    >Add Company</v-btn
                  >
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>

        <v-tab-item>
          <AdminProfile :company="getFreelancerCompany" />
        </v-tab-item>

        <v-tab-item>
          <v-row>
            <v-col xxs="12">
              <v-form ref="sendForm" v-model="valid" lazy-validation>
                <v-card class="mb-4 pa-5">
                  <v-row>
                    <v-col lg="1" class="mt-1 mx-4">
                      <v-img
                        alt="home"
                        :src="getFreelancerCompany.CertificateOfIncoperation"
                        width="30px"
                        height="30px"
                      />
                    </v-col>
                    <v-col lg="10">
                      <v-file-input
                        accept="image/*"
                        dense
                        label="Certificate Of Incoperation"
                        @change="onCertificateOfIncoperationFileChange"
                        truncate-length="50"
                        outlined
                        counter
                        show-size
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col lg="1" class="mt-1 mx-4">
                      <v-img
                        alt="home"
                        :src="getFreelancerCompany.MemorandamOfAsociation"
                        width="30px"
                        height="30px"
                      />
                    </v-col>
                    <v-col lg="10">
                      <v-file-input
                        accept="image/*"
                        dense
                        label="Memorandam Of Asociation"
                        @change="onMemorandamOfAsociationFileChange"
                        truncate-length="50"
                        outlined
                        counter
                        show-size
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col lg="1" class="mt-1 mx-4">
                      <v-img
                        alt="home"
                        :src="getFreelancerCompany.CampanyDirectors"
                        width="30px"
                        height="30px"
                      />
                    </v-col>
                    <v-col lg="10">
                      <v-file-input
                        accept="image/*"
                        dense
                        label="Campany Directors"
                        @change="onCampanyDirectorsFileChange"
                        truncate-length="50"
                        outlined
                        counter
                        show-size
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col lg="1" class="mt-1 mx-4">
                      <v-img
                        alt="home"
                        :src="getFreelancerCompany.ApplicantNationalID"
                        width="30px"
                        height="30px"
                      />
                    </v-col>
                    <v-col lg="10">
                      <v-file-input
                        accept="image/*"
                        dense
                        label="Applicant National ID"
                        @change="onApplicantNationalIDFileChange"
                        truncate-length="50"
                        outlined
                        counter
                        show-size
                      />
                    </v-col>
                  </v-row>
                </v-card>
              </v-form>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import AdminProfile from "@/components/AdminProfile.vue";

export default {
  template: "#page",

  components: {
    AdminProfile,
  },

  data() {
    return {
      file: null,
      valid: false,
      rules: {
        required: (value) => !!value || "This field is required",
        email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
      },

      companyFormsViews: [
        { text: "Company Profile Info ", value: "companyInfo" },
        { text: "Company Owner Info ", value: "companyInfo" },
        { text: "Company Documents ", value: "companyDocuments" },
      ],

      selectedForm: {},
    };
  },
  beforeCreate() {
    this.$store.dispatch("fetchMyCompany");
  },

  computed: {
    ...mapState(["freelancerCompany"]),
    ...mapGetters(["getFreelancerCompany", "messageAlert"]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.$store.dispatch("fetchMyCompany");
      }
    },

    getActiveCompany(val) {
      this.$store.dispatch("fetchMyCompany");
      this.$forceUpdate();
    },

    getFreelancerCompany(val) {
      this.$store.dispatch(
        "fetchMyProfile",
        this.getFreelancerCompany.clientID
      );
    },
  },

  methods: {
    validateCompanyUpdateForm() {
      if (this.$refs.sendForm.validate()) {
        // send it
        this.updateCompany();
      }
    },

    validateNewCompanyForm() {
      if (this.$refs.sendForm.validate()) {
        // send it
        this.submitCompany();
      }
    },

    submitCompany() {
      var metadata = {
        contentType: "image/jpeg",
      };
      var goON = true;
      const context = this;

      if (context.file === null) {
        goON = false;
        this.$notify("error", "Registration Error", "Company Avator Required", {
          duration: 3000,
          permanent: false,
        });
      }

      if (goON) {
        // Create a root reference
        var storageRef = firebase.storage().ref();

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef
          .child("webimages/api/accounts/" + context.file.name)
          .put(context.file, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          function(error) {
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;

              case "storage/canceled":
                // User canceled the upload
                break;

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          function() {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(function(downloadURL) {
                console.log("File available at", downloadURL);
                context.getFreelancerCompany.companyAvator = downloadURL;
                context.getFreelancerCompany.location = {};
                console.log(context.getFreelancerCompany);
                context.$store.dispatch(
                  "addNewCompany",
                  context.getFreelancerCompany
                );
              });
          }
        );
      }
    },

    updateCompany() {
      var metadata = {
        contentType: "image/jpeg",
      };

      var goON = true;
      const context = this;

      if (goON) {
        if (context.file === null) {
          console.log(context.getFreelancerCompany);
          context.$store.dispatch(
            "updateCompanyProfile",
            context.getFreelancerCompany
          );
        } else {
          // Create a root reference
          var storageRef = firebase.storage().ref();

          // Upload file and metadata to the object 'images/mountains.jpg'
          var uploadTask = storageRef
            .child("webimages/api/accounts/" + context.file.name)
            .put(context.file, metadata);

          // Listen for state changes, errors, and completion of the upload.
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
              // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log("Upload is " + progress + "% done");
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log("Upload is paused");
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log("Upload is running");
                  break;
              }
            },
            function(error) {
              switch (error.code) {
                case "storage/unauthorized":
                  // User doesn't have permission to access the object
                  break;

                case "storage/canceled":
                  // User canceled the upload
                  break;

                case "storage/unknown":
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            },
            function() {
              // Upload completed successfully, now we can get the download URL
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then(function(downloadURL) {
                  console.log("File available at", downloadURL);
                  context.getFreelancerCompany.companyAvator = downloadURL;
                  console.log(context.getFreelancerCompany);
                  context.$store.dispatch(
                    "updateCompanyProfile",
                    context.getFreelancerCompany
                  );
                });
            }
          );
        }
      }
    },

    getAddressData: function(addressData, placeResultData, id) {
      this.getFreelancerCompany.location = addressData;
      console.log(this.getFreelancerCompany.location);
    },

    onCertificateOfIncoperationFileChange(e) {
      console.log(e);
      this.$store.dispatch("UploadCompanyFileToFirebase", {
        file: e,
        fileName: "CertificateOfIncoperation",
      });
    },

    onMemorandamOfAsociationFileChange(e) {
      console.log(e);
      this.$store.dispatch("UploadCompanyFileToFirebase", {
        file: e,
        fileName: "MemorandamOfAsociation",
      });
    },

    onCampanyDirectorsFileChange(e) {
      console.log(e);
      this.$store.dispatch("UploadCompanyFileToFirebase", {
        file: e,
        fileName: "CampanyDirectors",
      });
    },

    onApplicantNationalIDFileChange(e) {
      console.log(e);
      this.$store.dispatch("UploadCompanyFileToFirebase", {
        file: e,
        fileName: "ApplicantNationalID",
      });
    },
  },
  created() {
    console.log(this.getFreelancerCompany);
  },
};
</script>
